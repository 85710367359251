// @ts-ignore
import formatMoney from 'accounting-js/lib/formatMoney';
import BigNumber from "bignumber.js";
BigNumber.config({DECIMAL_PLACES: 6, ROUNDING_MODE: BigNumber.ROUND_HALF_DOWN});

export default function (value: any, type = 'up'){
    if(!value){
        return '-';
    }
    const roundingMode = (type === 'up') ? BigNumber.ROUND_HALF_UP : BigNumber.ROUND_HALF_DOWN;
    return formatMoney(new BigNumber(value).toFixed(2,roundingMode), {symbol: '$ '});
}