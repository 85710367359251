// @ts-ignore
export default function(value) {
    if (!value) return "";
    return value == "01"
      ? "Efectivo"
      : value == "02"
      ? "Cheque nominativo"
      : value == "03"
      ? "Transferencia"
      : value == "04"
      ? "Tarjeta de crédito"
      : value == "28"
      ? "Tarjeta de débito"
      : value == "99"
      ? "Credito"
      : value;
  }
  