import initials from './initials';
import iniciales from './iniciales';
import fecha from './fecha';
import datetime from './datetime';
import {getFiles, getServer} from './upload';
import money from "./money";
import whatsapp from "./whatsapp";
import folio from "./folio";
import capitalize from "./capitalize";
import m_unit from "./m_unit";
import p_unit from "./p_unit";
import timeFrom from "./timefrom";

export default {
  initials,
  iniciales,
  fecha,
  datetime,
  getFiles,
  getServer,
  money,
  whatsapp,
  folio,
  capitalize,
  m_unit,
  p_unit,
  timeFrom
};