import {API_URL} from "@/providers/config";

export function getFiles (value: any){
    return value ? [value] : [];
}

export function getServer (empleado: any){
    return {
        url: API_URL + 'empleados/files/' + empleado?.id_empleado
    };
}