import {createApp} from 'vue';
import useAuth from '@/providers/auth';
import App from '@/App.vue'
import router from "@/router";
import filters from "@/filters/filters";

import 'intl-tel-input/build/css/intlTelInput.css';
import '@/assets/css/global.css';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
//import exportingInit from 'highcharts/modules/exporting';
//import offlineOption from "highcharts/modules/offline-exporting";
//import Exporting from 'highcharts/modules/exporting';

import HC_more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";

//exportingInit(Highcharts);
//offlineOption(Highcharts);
//Exporting(Highcharts);

HC_more(Highcharts);
HC_exporting(Highcharts);

import Maska from "maska";

const {authCheck} = useAuth();

//
const app = createApp(App);

//Filters
app.config.globalProperties.$filters = filters;

//Auth
authCheck().then(() => {
    app.use(router);
    return router.isReady();
});
//Mascaras
app.use(Maska);

//Graficas
app.use(HighchartsVue, {
    highcharts: Highcharts
})

//Mount
router.isReady().then(() => {
    app.mount('#app');
});