import { reactive, computed, toRefs } from "vue";
import { api, storage } from "./api";
import Swal from "sweetalert2";

interface State {
  user: any;//se quitó el user?: any
  init: boolean;
  token: string | undefined;
}

const state = reactive<State>({
  user: null,
  init: false,
  token: undefined,
});

export default function() {
  state.token = storage.get("token");

  function authCheck() {
    return new Promise((resolve) => {
      if (state.token === undefined) {
        state.init = true;
        resolve(false);
      } else {
        !state.init &&
          api
            .post("users/validate", { token: state.token })
            .then(({ data }) => {
              if (data.error) {
                logout();
                Swal.fire("Error", data.message, "error");
              } else {
                state.user = data.user;
              }
              state.init = true;
              resolve(true);
            });
      }
    });
  }

  function login(token: string, user: any) {
    storage.set("token", token);
    state.token = token;
    state.user = user;
  }

  function logout() {
    storage.clear();
    state.token = undefined;
    state.user = null;
  }


  const isAdmin = computed(() => {  
    return ["administrador", "superadmin", "developer"].includes(state.user?.role);
  });

  const isProduccion = computed(() => {
    return ["produccion"].includes(state.user?.role);
  });

  const isCompras = computed(() => {
    return ["compras", "administrador", "superadmin", "developer" ].includes(state.user?.role);
  });

  const isCalidad_almacen = computed(() => {
    return ["calidad_almacen"].includes(state.user?.role);
  });

  const isLogistica = computed(() => {
    return ["logistica"].includes(state.user?.role);
  });

  const isGerente = computed(() => {
    return ["gerente"].includes(state.user?.role);
  });

  const isVentas = computed(() => {
    return ["ventas"].includes(state.user?.role);
  });

  const isVendedor = computed(() => {
    return ["ventas"].includes(state.user?.role);
  });

  const isCredito = computed(() => {
    return ["credito"].includes(state.user?.role);
  });

  const isCobranza = computed(() => {
    return ["cobranza"].includes(state.user?.role);
  });

  return {
    ...toRefs(state),
    login,
    logout,
    authCheck,
    isAdmin,
    isProduccion,
    isCompras,
    isCalidad_almacen,
    isLogistica,
    isGerente,
    isVendedor,
    isVentas,
    isCredito,
    isCobranza,
  };
}
