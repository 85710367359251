<template>
  <div id="overlay" v-if="props.state.active">
    <div id="content">
      <img class="loader" src="@/assets/images/loaders/puff.svg" :alt="props.state.message" />
      {{props.state.message}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    state: {
      active: Boolean,
      message: String
    }
  },
  setup(props){
    return { props };
  }
}
</script>

<style scoped>
  #overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
  #content{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    color: #eeeeee;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
  }
  .loader {
    display: block;
    margin: 0 auto;
    width: 120px;
  }
</style>